import React from "react"
import Layout from "../../components/member/Layout"
import Feature from "../../components/member/Feature"

const Index = () => {
  const pageSlug = "member"
  const pageTitle = "箕澤屋クラウド"
  return (
    <div>
      <Layout pageSlug={pageSlug} pageTitle={pageTitle}>
        <Feature />
      </Layout>
    </div>
  )
}

export default Index
