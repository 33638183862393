import React from "react"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import Social from "../Social"

const useStyles = makeStyles(theme => ({
  lead: {
    fontSize: "0.85rem",
    margin: "0.5rem 0 1rem",
  },
  button: {
    background: "none",
    marginBottom: "2rem",
    padding: "1rem 0.5rem",
    boxShadow: "none",
    border: "2px solid #349681",
    color: "#349681",
    fontWeight: "bold",
    "&:hover": {
      background: "#eee",
      boxShadow: "none",
    },
  },
  social: {
    marginBottom: "2rem",
    "& .socialLink": {
      marginRight: "1.2rem",
      width: "20px",
      "& svg": {
        fill: "#262b35",
        width: "100% !important",
      },
    },
  },
}))

export default () => {
  const classes = useStyles()
  return (
    <>
      <Typography gutterBottom className={classes.lead}>
        ニュースレターも不定期にてお届け中！
      </Typography>
      <Button
        href="https://landing.mailerlite.com/webforms/landing/l3h9n1"
        rel="noopener noreferrer"
        target="_blank"
        variant="contained"
        className={classes.button}
        fullWidth
      >
        ニュースレター登録フォーム
      </Button>
      <div className={classes.social}>
        <Social />
      </div>
    </>
  )
}
