import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos"
import LaunchIcon from "@material-ui/icons/Launch"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Paper from "@material-ui/core/Paper"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import Divider from "@material-ui/core/Divider"
import styles from "../../assets/scss/member/Feature.module.scss"
import Newsletter from "./Newsletter"

// function ListItemLink(props) {
//   return <ListItem button component="a" {...props} />
// }

export default () => {
  const data = useStaticQuery(graphql`
    query {
      galleryImage1: file(relativePath: { eq: "member/album/gallery1.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 200) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      galleryImage2: file(relativePath: { eq: "member/album/gallery2.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 200) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      galleryImage3: file(relativePath: { eq: "member/album/gallery3.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 200) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      galleryImage4: file(relativePath: { eq: "member/album/gallery4.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 200) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      shopImage1: file(relativePath: { eq: "member/shop/img_fruits.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 200) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      shopImage2: file(relativePath: { eq: "member/shop/img_hazydaze.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 200) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <div className={styles.root}>
      <Typography className={styles.lead}>
        箕澤屋クラウドへようこそ！
        <br />
        このページは、箕澤屋メンバー（サポーター）の情報共有の場です。
      </Typography>

      <Grid container spacing={4}>
        <Grid item xs={12} md={8}>
          <Typography variant="h2" className={styles.heading}>
            Misawaya News
          </Typography>
          <Paper className={styles.news}>
            <List component="ul" disablePadding={true}>
              <ListItem className={styles.news__list}>
                <ListItemText
                  primary={
                    <>
                      <Typography
                        component="span"
                        color="textPrimary"
                        className={styles.news__date}
                      >
                        2020.7.10
                      </Typography>
                      <Typography
                        variant="h3"
                        className={styles.news__heading}
                        color="textPrimary"
                      >
                        In a daze brewing
                        で今年も2020年度ネクタリンビール仕込み中！
                      </Typography>
                    </>
                  }
                  secondary={
                    <Typography
                      component="div"
                      variant="body"
                      color="textPrimary"
                      className={styles.news__content}
                    >
                      In a daze Brewing
                      のKazueちゃんが、今年も当農園のネクタリンを使って、ネクタリンビールを仕込んでくれています。香り高いHazy
                      IPAで昨年箕澤屋でも大好評だったやつ。7月20日の週から提供開始予定とのことなので要チェック！
                      <a
                        href="https://misawayanohanashi.com/hazydaze-nectarine-beer-2020/"
                        rel="noopener noreferrer"
                        target="_blank"
                        className={styles.news__link}
                      >
                        Blog:
                        旬のネクタリンやあんずがビールになるよ！自家農園のこといろいろ
                        <LaunchIcon className={styles.link__arrow} />
                      </a>
                      <a
                        href="https://www.inadazebrewing.com/"
                        rel="noopener noreferrer"
                        target="_blank"
                        className={styles.news__link}
                      >
                        In a daze Brewing
                        <LaunchIcon className={styles.link__arrow} />
                      </a>
                    </Typography>
                  }
                />
              </ListItem>
              <Divider light="true" />
              <ListItem className={styles.news__list}>
                <ListItemText
                  primary={
                    <>
                      <Typography
                        component="span"
                        color="textPrimary"
                        className={styles.news__date}
                      >
                        2020.7.9
                      </Typography>
                      <Typography
                        component="h3"
                        className={styles.news__heading}
                        color="textPrimary"
                      >
                        MISAWAYA CLOUD(β) テスト運用開始！
                      </Typography>
                    </>
                  }
                  secondary={
                    <Typography
                      component="div"
                      variant="body"
                      color="textPrimary"
                      className={styles.news__content}
                    >
                      2020年は公開イベントがキャンセルになってしまったので、今年はテスト運用ということで、手始めに箕澤屋の動きの共有などから始めてみたいと思います。
                    </Typography>
                  }
                />
              </ListItem>
              <Divider light="true" />
              <ListItem
                rel="noopener noreferrer"
                target="_blank"
                className={styles.news__list}
              >
                <ListItemText
                  primary={
                    <>
                      <Typography
                        component="span"
                        color="textPrimary"
                        className={styles.news__date}
                      >
                        2020.6.23
                      </Typography>
                      <Typography
                        component="h3"
                        className={styles.news__heading}
                        color="textPrimary"
                      >
                        2020年の公開イベントはすべてキャンセルいたします
                      </Typography>
                    </>
                  }
                  secondary={
                    <Typography
                      component="div"
                      variant="body"
                      color="textPrimary"
                      className={styles.news__content}
                    >
                      新型コロナウィルスの感染拡大リスクがあるため、2020年の公開イベントはすべて中止といたしました。年に一度の一大イベントが開催できず、とても残念です。しかしその分、今年はオンライン上での活動に力を入れてまいります！
                      <a
                        href="https://misawayanohanashi.com/about-summer-event-2020/"
                        rel="noopener noreferrer"
                        target="_blank"
                        className={styles.news__link}
                      >
                        Blog: 2020年夏の公開イベントや営業についてのお知らせ
                        <LaunchIcon className={styles.link__arrow} />
                      </a>
                    </Typography>
                  }
                />
              </ListItem>
            </List>
          </Paper>

          <Grid
            container
            spacing="2"
            justify="space-between"
            alignItems="center"
          >
            <Grid item xs={6}>
              <Typography variant="h2" className={styles.heading}>
                Photo Album
              </Typography>
            </Grid>
            <Grid item xs={4} className={styles.readmore}>
              <Link to="/member/album">
                もっと見る
                <ArrowForwardIosIcon className={styles.arrow} />
              </Link>
            </Grid>
          </Grid>

          <Paper className={styles.album}>
            <Grid
              container
              spacing="3"
              justify="space-between"
              alignItems="center"
            >
              <Grid item xs={6} md={3}>
                <Img fluid={data.galleryImage1.childImageSharp.fluid} alt="" />
              </Grid>
              <Grid item xs={6} md={3}>
                <Img fluid={data.galleryImage2.childImageSharp.fluid} alt="" />
              </Grid>
              <Grid item xs={6} md={3}>
                <Img fluid={data.galleryImage3.childImageSharp.fluid} alt="" />
              </Grid>
              <Grid item xs={6} md={3}>
                <Img fluid={data.galleryImage4.childImageSharp.fluid} alt="" />
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12} md={4}>
          <Grid
            container
            spacing="2"
            justify="space-between"
            alignItems="center"
          >
            <Grid item xs={6}>
              <Typography variant="h2" className={styles.heading}>
                Events
              </Typography>
            </Grid>
            <Grid item xs={4} className={styles.readmore}>
              <Link to="/member/events">
                もっと見る
                <ArrowForwardIosIcon className={styles.arrow} />
              </Link>
            </Grid>
          </Grid>

          <Paper className={styles.events}>
            <List component="ul" disablePadding={true}>
              <ListItem>
                <ListItemText
                  className={styles.events__text}
                  primary="春の大掃除"
                  secondary="2021.5.22 10:00〜15:00 (JST) "
                />
              </ListItem>
              <Divider light="true" />
              <ListItem>
                <ListItemText
                  className={styles.events__text}
                  primary="サポーター釣り＆BBQ大会"
                  secondary="2021.6.5 10:00〜20:00 (JST) "
                />
              </ListItem>
              <Divider light="true" />
              <ListItem>
                <ListItemText
                  className={styles.events__text}
                  primary="箕澤屋の日 2021"
                  secondary="2021.7.25 10:00〜14:00 (JST) "
                />
              </ListItem>
              <Divider light="true" />
              <ListItem>
                <ListItemText
                  className={styles.events__text}
                  primary="箕澤屋かき氷カフェ"
                  secondary="2021.7.31〜8.22 12:00〜16:00 (JST) "
                />
              </ListItem>
            </List>
          </Paper>

          <Grid
            container
            spacing="2"
            justify="space-between"
            alignItems="center"
          >
            <Grid item xs={6}>
              <h2 className={styles.heading}>Shop</h2>
            </Grid>
            <Grid item xs={4} className={styles.readmore}>
              <Link to="/member/shop">
                もっと見る
                <ArrowForwardIosIcon className={styles.arrow} />
              </Link>
            </Grid>
          </Grid>

          <Paper className={styles.shop}>
            <Grid
              container
              spacing="3"
              justify="space-between"
              alignItems="flex-start"
            >
              <Grid item xs={6} className={styles.shop__list}>
                <Img fluid={data.shopImage1.childImageSharp.fluid} alt="" />
                <Typography className={styles.shop__text}>
                  旬のフルーツ
                </Typography>
              </Grid>
              <Grid item xs={6} className={styles.shop__list}>
                <Img fluid={data.shopImage2.childImageSharp.fluid} alt="" />
                <Typography className={styles.shop__text}>
                  ネクタリンビール
                </Typography>
              </Grid>
            </Grid>
          </Paper>

          <Typography variant="h2" className={styles.heading}>
            Newsletter
          </Typography>
          <Newsletter />
        </Grid>
      </Grid>
    </div>
  )
}
